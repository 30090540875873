<template>
  <div class="pages">
    <div class="task_box">
    <div class="dialog">
      <div class="zuBg">
      <div class="btnBg">
        <div class="title">预警设置</div>
        <div class="inputList">
          <div class="iptTitle">局放数值</div>
          <div class="inp">
            <input type="text" v-model="partial_discharge_thresh" placeholder="请输入局放监测阀值">
          </div>
        </div>
        <div class="inputList">
          <div class="iptTitle">局放预警</div>
          <div class="inp">
            <input type="text" v-model="partial_discharge" placeholder="请输入连续监测局放超阀值的次数">
          </div>
        </div>
        
        <div class="inputList">
          <div class="iptTitle">水浸预警</div>
          <div class="inp">
            <input type="text" v-model="water_immersion" placeholder="请输入超过设定值">
          </div>
        </div>
           <div class="inputList">
          <div class="iptTitle">失压预警</div>
          <div class="inp">
            <input type="text" v-model="loss_of_pressure" placeholder="请输入电池电压最小值">
          </div>
        </div>
          <div class="inputList">
          <div class="iptTitle">故障预警</div>
          <div class="inp">
            <input type="text" v-model="fault" placeholder="请输入连续无数据上传次数">
          </div>
        </div>
        <div class="tijiao" @click="submit">提交</div>
      </div>
    </div>
    </div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      siteList: [],
      customProps: {
        checkStrictly: true,
        multiple: true,
        children: "children",
        value: "id",
        label: "title",
      },
        zuIdname: "",
        organize_id: '',
        name: '',
        password: '',
        role: '',
        group_id: '', //角色id
        roleList: [],
        partial_discharge: '',
        water_immersion: '',
        loss_of_pressure: '',
        partial_discharge_thresh:'',
        fault: ''
    };
  },
      mounted() {
    // 获取表格列表数据
    this.getTypeList()
  this.getzuzhiList()
  },
  methods: {
  async submit(){
    
     const res = await this.$http.post('Earlywarning/handle', {
        partial_discharge: this.partial_discharge,
        partial_discharge_thresh:this.partial_discharge_thresh,
        water_immersion: this.water_immersion,
        loss_of_pressure:this.loss_of_pressure,
        fault: this.fault
      }
     )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.$message.success(res.msg)

    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 角色获取
       async getTypeList() {
      const res = await this.$http.post('user/user_group')
      this.roleList = res.data.list
    },
    changeRole(e) {
      console.log(e,'ee');
      this.group_id = e
    },
     // 组织 获取
       async getzuzhiList() {
      const res = await this.$http.post('Organize')
      this.siteList = res.data
    },
      handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
        // let lastValue;
        // data.forEach(subArr => {
        //     if (Array.isArray(subArr)) {
        //         subArr.forEach((value) => {
        //             lastValue = value;
        //         });
        //     } else {
        //         lastValue = subArr;
        //     }
        // });
        // console.log(lastValue,'lastValue');
        // this.organize_id = lastValue
         let res = this.$refs.tree.getCheckedNodes()
          let arr = []
          res.forEach((item) => {
            arr.push(item.data.id)
          })
          this.organize_id = arr.join(',');
      },
  
  },
};
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.task_box {
  width: calc(100% - 220px);
}
.dialog {
  // position: relative;
  .zuBg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    // height: 643px;
  }
  .btnBg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 1111px;
    height: 806px;
    background: url("../../assets/images/editDialog.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .btnBg .title {
    font-size: 36px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4486FF;
    margin-top: 57px;
    margin-bottom: 57px;
  }
  .inputList {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border: 2px solid #4486FF;
    border-radius: 4px;
    margin-top: 13px;
    width: 610px;
    height: 50px;
    margin-bottom: 40px;
    .icon {
      width: 26px;
      height: 24px;
    }
    padding: 0 40px;
    box-sizing: border-box;
  }
  .iptTitle {
    width: 130px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 30px;
  }
  input {
      width: 300px;
    height: 52px;
    font-size: 20px;
    outline: none;
    border: none;
  }
  .inp input {
    background: rgba(0, 0, 0, 0);
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
  .inputList .el-select > .el-input {
    border: none !important;
    height: 56px;
    background: rgba(0, 0, 0, 0);
  }
  .el-select {
    width: 300px !important;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .el-cascader {
    cursor: pointer;
    width: 300px;
  }
  ::v-deep .el-input__inner {
    margin-top: 2px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #666 !important;
    height: 52px;
    border: none;
    width: 420px !important;
    padding-left: 0;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .inputList .el-select .el-input .el-select__caret {
    font-size: 26px !important;
  }
  .inputList .el-input__icon {
    line-height: 51px !important;
  }
  .tijiao {
    width: 160px;
    height: 50px;
    background: #4486FF;
    border-radius: 4px;
    font-size: 29px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-top: 29px;
  }
  .close {
    position: absolute;
    top: 183px;
    right: 455px;
    width: 36px;
    height: 36px;
    z-index: 1000000;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>